
    import Lenis from 'lenis';

    const lenis = new Lenis();


    function raf(time) {
        lenis.raf(time);
        requestAnimationFrame(raf);
    }
    requestAnimationFrame(raf);

    /**
     * Menu
     */

    const projectsLinks = document.querySelectorAll('a.link');
    const iFrame = document.querySelector('.iframe');
    const closeButton = document.querySelector('.close-icon');
    const menu = document.querySelector('.menu');

    /**
     * Closing menu
     */

    closeButton.addEventListener('click', () => {
        menu.classList.toggle('closed');
        iFrame.classList.toggle('resized');
        closeButton.classList.toggle('replace');
    });

    /**
     * Update Iframe
     */

    let loadQuery = location.search.split('src=');
    let codeIcon = document.querySelector('.code-icon');
    const url = 'https://github.com/tomthrr/the-lab/tree/main/src/'

    if (loadQuery[1]) {
        // si le fichier n'existe pas ou n'est pas dans l'url
        let isThere = false;
        projectsLinks.forEach((link) => {
            if (link.dataset.src === loadQuery[1]) {
                isThere = true;
            }
        });
        if (isThere) {
            // on load un projet spécifique
            iFrame.src = loadQuery[1];
            highlight(loadQuery[1]);
            codeIcon.href = `${url}${loadQuery[1]}`;
        } else {
            // sinon un projet random
            let target = projectsLinks[Math.floor(Math.random() * projectsLinks.length)];
            highlight(target.dataset.src);
            updateExample({ target });
        }

    } else {
        // On load un projet random
        let target = projectsLinks[Math.floor(Math.random() * projectsLinks.length)];
        updateExample({ target });
        highlight(target.dataset.src);
    }

    projectsLinks.forEach((link) => {
        link.addEventListener('mouseenter', () => link.classList.add('hovered'));
        link.addEventListener('mouseleave', () => link.classList.remove('hovered'));
        link.addEventListener('click', updateExample, false);
    });

    function    updateExample(e) {
        unHighlight();
        let src = e.target.dataset.src;
        // update code link
        codeIcon.href = `${url}${src}`;

        // Control clique possible
        if (e.metaKey) {
            window.open(`${location.origin}${location.pathname}?src=${src}`, '_blank');
            return;
        }

        iFrame.src = e.target.dataset.src;
        highlight(src);

        history.pushState(null, null, `${location.origin}${location.pathname}?src=${src}`);
        closeButton.classList.toggle('replace');
        menu.classList.toggle('closed');
        iFrame.classList.toggle('resized');
        e.preventDefault && e.preventDefault();
    }

    function highlight(src) {
        projectsLinks.forEach((link) => {
            if (src === link.dataset.src) link.classList.add('active');
            else link.classList.remove('active');
        });
    }

    function unHighlight() {
        projectsLinks.forEach((link) => {
            link.classList.remove('active');
        });
    }
